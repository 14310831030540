:root {
  .card-header h2 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }

  .technologies {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin: 1rem 0 0 0;
    padding: 0 1.5rem;
  }
}