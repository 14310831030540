:root {
  .app {
    background: var(--background-color);
    color: var(--text-color);
    font-family: 'Encode Sans Expanded', sans-serif;
    font-size: var(--base-rem);
    line-height: 1.5;
    min-height: 100%;
    width: 100%;
  
    /* Global Styles */
    .page-title {
      color: var(--title-color);
      font-family: 'Oswald', serif;
      font-size: 3.5rem;
      margin-bottom: 1rem;
    }
  
    a {
      text-decoration: none;
    }

    .title {
      background: var(--background-color);
      border-bottom: var(--border-style);
      color: var(--title-color);
      font-family: 'Oswald', serif;
      font-size: 1.5rem;
      margin: 1rem 0;
      padding: 1rem 1.5rem;
      position: sticky;
      top: 0;
      z-index: var(--content-layer-top);
    }
  
    .sub-title {
      font-size: 1.5rem;
      margin: 1rem 0;
    }
  
    .flashlight-effect {
      pointer-events: none;
      position: fixed;
      inset: 0;
      z-index: var(--effect-layer);
    }
  
    .pill {
      font-size: 0.75rem;
      padding: 0.25rem 0.75rem;
      color: var(--pill-text);
      border-radius: 1rem;
      background-color: var(--pill-background);
  
      &.button {
        text-decoration: none;
      }
    }
  
    .bold {
      font-weight: var(--font-weight-bold);
      color: var(--bold-color);
    }
  
    .wrapper {
      padding: 0 1.5rem 1rem 1.5rem;
    }
  
    .info {
      margin-bottom: 1rem;
  
      &::before {
        background: var(--accent-color);
        content: '';
        display: inline-block;
        height: 16px;
        margin: 0.5rem 0.5rem -0.2rem 0;
        opacity: 0.5;
        width: 2px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  
    .resume {
      max-width: 80rem;
      margin: 0 auto;
      position: relative;
      z-index: var(--content-layer);
    }

    .card {
      padding: 1.5rem 0 3.5rem 0;
      border: 1px solid transparent;
      border-bottom: var(--border-style);
  
      &:last-child {
        border-bottom: 0px solid transparent;
      }
  
      .subtitle {
        color: var(--dark-text-color);
        font-size: 0.75rem;
        text-transform: uppercase;
      }
  
      .card-header, .description, .links {
        padding: 1rem 1.5rem;
      }
  
      .card-header {
        background: var(--background-color);
        font-weight: var(--font-weight-bold);
        margin: 1rem 0;
        position: sticky;
        top: 4.3rem;
        z-index: var(--content-layer);
      }
    }
  }

  /* tablet */
  @media screen and (min-width: 440px) {
    .app {
      .title {
        position: unset;
        background: unset;
      }

      .card {
        border: 1px solid transparent;
        margin: 2rem 0;
        padding: 0;

        &:hover {
          border-bottom: var(--border-style);
          border-radius: 1rem;
          border-top: var(--border-style);
          background-color: var(--hover-background);
        }

        .card-header {
          background: unset;
          position: unset;
          padding: 1rem 1.5rem 0 1.5rem
        }

        .technologies {
          margin: unset;
          padding: 0 1.5rem 2rem 1.5rem;
        }
      }
    }
  }

  /* desktop */
  @media screen and (min-width: 768px) {
    .app {
      .resume {
        display: flex;
        justify-content: space-between;
        max-width: 80rem;
        margin: 0 auto;
        padding: 2rem;
        position: relative;
        z-index: var(--content-layer);
    
        .side-bar {
          height: fit-content;
          max-width: 30rem;
          min-width: 16rem;
          padding: 2rem;
          position: sticky;
          top: 2rem;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 80vh;
        }
      }
    }
  }
}
