:root {
    /* print colors */
    --print-background-color: transparent;
    --print-text-color: #0F172A;
    --print-title-color: #0F172A;
    --print-border-style: 1px solid #5EEAD4;
    --print-bold-color: #0F172A;
    --print-accent-color: #5EEAD4;

    /* measurements */
    --base-rem: 16px;

    /* fonts */
    --font-weight-bold: 700;
    
    /* z-index */
    --effect-layer: 1;
    --content-layer: 2;
    --content-layer-top: 3;
    --nav-layer: 4;
    --nav-layer-top: 5;

    .darkmode {
      /* colors */
      --background-color: #0F172A;
      --accent-color: #5EEAD4;
      --title-color: #E5E7EB;
      --bold-color: #bec7d4;
      --text-color: #94A3B8;
      --dark-text-color: #64748B;
      --logo-color: #94A3B8;
      --hover-background: rgba(229,231,235, 0.1);
      --pill-background: rgba(94, 234, 212, 0.15);
      --pill-text: #5EEAD4;
      --menu-background: #0F172A;
      --highlight-color: #0F172A;

      /* borders */
      --border-style: 1px solid rgba(226, 232, 240, 0.10);
    }

    .lightmode {
      /* colors */
      --background-color: #eff1f3;
      --accent-color: #5EEAD4;
      --title-color: #0F172A;
      --bold-color: #465161;
      --text-color: #64748B;
      --dark-text-color: #465161;
      --logo-color: #0F172A;
      --hover-background: rgba(100,116,139, 0.10);
      --pill-background: rgba(94, 234, 212, 0.3);
      --pill-text: #0F172A;
      --menu-background: #eff1f3;
      --highlight-color: #0F172A;

      /* borders */
      --border-style: 1px solid rgba(15, 23, 42, 0.10);
    }
}
  