:root {
  .card.education {
    border-bottom: 1px solid transparent;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;

    .card-header.education {
      background: 1px solid transparent;
      order: 1;
      z-index: unset;

      .subtitle {
        margin-bottom: 0.5rem;
      }

      .course-info {
        font-size: 0.825rem;
      }
    }

    .logo {
      width: 11rem;
      margin: 2rem auto 0 auto;
      padding: 0 1.5rem;
      order: 0;

      g {
        fill: var(--logo-color);
      }
    }
  }

  @media screen and (min-width: 440px) {
    .card.education {
      flex-direction: row;
  
      .card-header.education {
        order: unset;
  
        .course-info {
          font-size: 1rem;
        }
      }
  
      .logo {
        width: 11rem;
        padding: 0 1.5rem 0 0;
        margin: 0;
        order: unset;
      }
    }
  }
}