:root {
  .content{
    .section {
      margin-bottom: 3rem;
    }
  }

  /* tablet */
  @media screen and (min-width: 440px) {
    .content{
      margin-top: 3.5rem;
    }
  }
}
