:root {
  .nav {
    font-family: 'Oswald', serif;
    font-size: 1.2rem;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--menu-background);
    z-index: 4;
    padding: 1.5rem;

    .nav-title {
      padding: 0 0 1rem 0;
      margin: 0 0 1rem 0;
      background: unset;
    }
    
    .nav-item {
      cursor: pointer;
      display: flex;
      gap: 1rem;
      align-items: center;
      margin-bottom: 1rem;
      color: var(--bold-color);


      &:hover {
        color: var(--accent-color);
      }

      .nav-icon {
        width: 1.5rem;
        height: 1.5rem;
        fill: var(--accent-color);
      }
    }
  }

  .settings {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--dark-text-color);
    padding: 0.75rem 1.5rem 0.5rem 1.5rem;
    display: flex;
    gap: 1rem;

    .setting-icon {
      cursor: pointer;
      fill: var(--menu-background);

      &.flashlight {
        display: none;
      }
    }
  }

  /* desktop */
  @media screen and (min-width: 768px) {
    .nav {
      position: unset;
      top: unset;
      left: unset;
      right: unset;
      bottom: unset;
      background: unset;
      z-index: 4;
      padding: 4rem 0 0 0;
  
      .nav-title {
        display: none;
      }
    }

    .settings {
      background: unset;

      .setting-icon {
        fill: var(--bold-color);

        &.flashlight {
          display: block;
        }
      }
    }
  }

  @media (pointer:coarse) {
    .settings { 
      .setting-icon.flashlight {
        display: none;
      }
    }
  }
}
