@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOutOpacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

:root {
  .side-bar {
    padding: 1.5rem;

    .navigation-container {
      z-index: var(--nav-layer);

      .hide-nav {
        display: none;
      }
  
      .show-nav {
        display: block;
        opacity: 1;
        animation-name: fadeInOpacity;
        animation-iteration-count: 1;
        animation-timing-function: ease-in;
        animation-duration: 200ms;
      }
  
      .menu {
        position: fixed;
        top: 0;
        right: 0;
        z-index: var(--nav-layer-top);
        padding: 1.3rem 1.5rem;
        line-height: 1;
      }
  
      .hamburger {
        fill: var(--accent-color);
      }
    }

    .print-only-contact {
      display: none;
      position: absolute;
      top: 1rem;
      right: 0;

      .print-item {
        color: var(--logo-color);
        cursor: pointer;
        display: flex;
        font-size: 0.825rem;
        gap: 0.5rem;
        align-items: center;
        margin-bottom: 0.5rem;
  
        .print-icon {
          width: 1rem;
          height: 1rem;
          fill: var(--accent-color);
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    .side-bar { 
      .navigation-container {   
        .show-nav {
          display: block;
          opacity: 1;
          animation-name: unset;
          animation-iteration-count: unset;
          animation-timing-function: unset;
          animation-duration: unset;
        }
      }
    }
  }
}
