@import url('https://fonts.googleapis.com/css2?&family=Encode+Sans+Expanded&family=Oswald:wght@500&display=swap');
@import './styles/variables.css';
@import './styles/reset.css';

html {
  width: 100% !important;
  height: 100% !important;
  background-color: var(--background-color) !important;
  margin: 0 auto !important;
  padding: 0 !important;
}

::selection {
  background-color: var(--accent-color); /* Highlight background color */
  color: var(--highlight-color);           /* Text color when highlighted */
}