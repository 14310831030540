@media print {
  :root {
    .pagebreak { page-break-before: always; } /* page-break-after works, as well */

    .app {
      background: var(--print-background-color);
      color: var(--print-text-color);
      font-family: 'Encode Sans Expanded', sans-serif;
      font-size: var(--base-rem);
      line-height: 1.5;

      .side-bar {
        padding: 0;
      }

      .page-title {
        color: var(--print-title-color);
        font-size: 2.5rem;
        margin-bottom: 0rem;
      }

      .sub-title {
        font-size: 1.5rem;
        margin: 0;
      }

      .tagline {
        font-size: 1rem;
        margin: 0;
      }

      .title {
        background: var(--print-background-color);
        border-bottom: var(--print-border-style);
        color: var(--print-title-color);
        font-family: 'Oswald', serif;
        font-size: 1.25rem;
        margin: 1rem 0 0.5rem 0;
        padding: 0;
        position: unset;
      }

      .wrapper {
        padding: 0;
        margin: 0;
      }

      .info, .about {
        font-size: 0.75rem;
        padding: 0;
        margin: 0.5rem 0;
      }

      .info::before {
        print-color-adjust: exact;
      }

      .bold {
        color: var(--print-bold-color);
      }

      .card {
        margin: 0 0 2rem 0;
        padding: 0;
        
        .card-header {
          position: unset;
          padding: 0;
          margin: 1rem 0;

          h2 {
            font-size: 1.25rem;
            margin-bottom: 0;
          }
        }

        .logo {
          padding-right: 0;

          g {
          fill: var(--print-title-color);
          }
        }

        .course-info {
          font-size: 0.75rem;
        }

        .technologies {
          padding: 0;
          margin: 0;

          .pill {
            border: var(--print-border-style);
            background-color: var(--print-accent-color);
            color: var(--print-text-color);
            padding: 0.25rem 0.5rem;
            font-size: 0.5rem;  
            font-weight: var(--font-weight-bold);
          }
        }

        .description {
          padding: 0;
          margin: 0;

          .info {
            font-size: 0.75rem;
            padding: 0;
            margin: 0.5rem 0;
            line-height: 1.2;
            margin-bottom: 0rem;
          }
        }

        .links {
          display: none;
        }
      }

      .card.education {
        display: block;
    
        .card-header.education {
          float: left;
          display: block;;

          .subtitle {
            margin-bottom: 0.5rem;
          }
    
          .course-info {
            font-size: 0.825rem;
          }
        }
    
        .logo {
          float: right;
          display: block;
          width: 11rem;
          margin: 2rem auto 0 auto;
          padding: 0 1.5rem;
          order: 0;
    
          g {
            fill: var(--print-text-color);
          }
        }
      }

      .print-only-contact {
        display: block;
      }
    }
  }
}
